<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <merchant-card v-if="userIsSuperAdmin || userIsCustomerSupport" v-bind="{item}" />
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
          <status class="small" v-bind="{item}" />
        </h1>

        <action-bar v-bind="{item,resource}" @action="action" />

        <timeline v-if="live && !expired" class="mx-4" :status="item.status" />

        <div class="row row-cols-1 row-cols-md-2 mt-4">
          <div class="col mb-4">

            <b-card class="mb-4">
              <template #header> 
                <i class='bx bxs-contact' />
                {{$t('shipments.consignee')}}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="common.name" icon="bx-purchase-tag" :value="item.name"/>
                  </div>
                  <!-- <div class="col">
                    <value label="common.country" icon="bx-globe" :value="item.country">
                      <country :country="item.country" />
                    </value>
                  </div> -->
                  <div class="col">
                    <value label="common.locale" icon="bx-message-square-detail" :value="$t(`locale.${item.locale}`)" />
                  </div>
                  <div class="col">
                    <value label="common.phone" icon="bx-phone" :value="'+'+item.full_phone_number"/>
                  </div>
                  <div class="col">
                    <value label="common.email" icon="bx-envelope" :value="item.email"/>
                  </div>
                </div>
              </dl>
            </b-card>

            <activity-card :item="item" />

          </div>
          <div class="col mb-4">

            <b-card>
              <template #header> 
                <i class='bx bxs-truck' />
                {{$t('shipments.label')}}
              </template>
              <dl>

                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="shipments.type" icon="bxs-timer" :value="item.type">
                      {{$t(`shipments.types.${item.type}`)}}
                    </value>
                  </div>
                  <div class="col">
                    <value label="shipments.external-reference" icon="bxs-purchase-tag" :value="item.external_reference_id"/>
                  </div>
                  <div class="col" v-if="item.manifest_id">
                    <value label="manifests.label" icon="bx-receipt" :value="item.manifest_id">
                      <router-link :to="{name: `manifests-show`, params: {id: item.manifest_id}}">
                        {{item.manifest_name}}
                      </router-link>
                    </value>
                  </div>
                  <div class="col" v-if="live">
                    <value label="shipments.reference_id" icon="bx-search-alt" :value="item.reference_id">
                      <a :href="`${trackHost}${item.reference_id}`" target="_blank">
                        {{item.reference_id}}
                      </a>
                    </value>
                  </div>
                  <div class="col">
                    <value v-if="item.contents" label="shipments.contents" icon="bxs-component" :value="item.contents"/>
                  </div>
                </div>

                <value label="shipments.pickup-location" icon="bx-map" :value="item.pickup_location_id">
                    <router-link v-if="item.pickup_location_id" :to="{name: `locations-show`, params: {id: item.pickup_location_id}}">
                      {{item.pickup_location_name}}
                    </router-link>
                </value>
                <value label="shipments.dropoff-location" icon="bx-map" :value="item.dropoff_location_id">
                    <router-link v-if="item.dropoff_location_id" :to="{name: `locations-show`, params: {id: item.dropoff_location_id}}">
                      {{item.dropoff_location_name}}
                    </router-link>
                </value>

              </dl>
            </b-card>

            <packages-card :shipment="item" :packages="item.$packages" />

          </div>
        </div>
      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemShow from '@/components/ItemShow'
import ActivityCard from '@/components/Cards/Activity'
import PackagesCard from '@/components/Cards/ShipmentPackages'
import Value from "@/components/Value";
import ActionBar from '@/components/ActionBar.vue'
import MerchantCard from '@/components/Cards/Merchant'
import Timeline from '@/components/Timeline.vue'
import Status from '@/components/Status.vue'
import { STATUS_EXPIRED, STATUS_REGISTERED } from '@/resources/Shipment'

export default {
  extends: ItemShow,
  components: { 
    MainLayout, 
    ActivityCard, 
    PackagesCard, 
    Value, 
    ActionBar, 
    MerchantCard,
    Timeline,
    Status,
  },
  data() {
    return {
      trackHost: process.env.VUE_APP_TRACK_HOST
    }
  },
  computed: {
    expired() {
      return this.item.status >= STATUS_EXPIRED
    },
    live() {
      return this.item.status >= STATUS_REGISTERED || this.expired
    }
  }
};
</script>