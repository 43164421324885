<template>
  <div>
    <div class="timeline">
      <div 
        v-for="(event,i) in filteredEvents" 
        :key="i" 
        class="event"
        :class="{
          active: event.code <= aproximatedStatus,
          current: event.code === aproximatedStatus,
          odd: i%2
        }"
        :style="{left: (i/(filteredEvents.length-1))*100 + '%'}"
      >
        <div class="event-label">{{$t(event.label)}}</div>
      </div>
      <div class="progress">
        <div class="progress-bar bg-primary" role="progressbar" :style="{width: progress}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Shipment from '@/resources/Shipment';

export default {
  props: {
    events: {
      type: Array,
      default: () => Shipment.statuses
    },
    status: Number,
  },
  computed: {
    filteredEvents() {
      return this.events.filter(e => e.timeline)
    },
    aproximatedStatus() {
      if(!this.filteredEvents.some(e => e.code === this.status)) {
        let i = this.filteredEvents.findIndex(e => this.status < e.code)
        return this.filteredEvents[i-1]?.code
      }
      return this.status
    },
    progress() {
      let i = this.filteredEvents.findIndex(e => e.code === this.aproximatedStatus)
      return (i/(this.filteredEvents.length-1))*100 + '%'
    }
  }
}
</script>

<style lang="scss">
  @import '@/css/variables.scss';

  .timeline {
    position: relative;
    margin-bottom: 4rem;
    margin-top: 3rem;

    @media (max-width:960px) {
      margin-top: 4rem;
    }

    @media (max-width:640px) {
      font-size: 2.5vw;
    }

    .event {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #FFFFFF;
      border: 3px solid #e9ecef;
      position: absolute;
      margin-top: -7.5px;
      margin-left: -15px;

      &.active {
        border: 3px solid $primary;
        background-color: $primary;

        &.current {
          background-color: #ffffff;
        }

        .event-label {
          color: $dark;
        }
      }

      .event-label {
        color: $gray-500;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        white-space: nowrap;
        text-align: center;
        margin-top: 30px;
      }

      &.odd {
        @media (max-width:960px) {
          .event-label {
            margin-top: -30px;
          }
        }
      }
    }
  }
</style>