<template>
  <b-card no-body>
    <template #header> 
      <b-button 
        v-if="shipment.$can('edit')" 
        class="float-right"
        variant="primary"
        :to="{ name: `packages-create`, params: {parent_id: shipment.id} }" 
      >
        <i class='bx bx-plus' ></i>
      </b-button>
      <i class='bx bxs-package' />
      {{$t('packages.title')}}
    </template>
    <b-table 
      small 
      striped 
      hover
      show-empty
      ref="table"
      :empty-text="$t('shipments.no-packages')"
      :items="packages" 
      :tbodyTrClass="{linkish: packages.length}"
      :fields="fields | transMap"
      @row-clicked="p => p.$open()"
    >
      <template #cell(index)="{index}">
        <span class="text-muted small">#</span><strong>{{index+1}}</strong>/<strong>{{packages.length}}</strong>
      </template>
      <template #cell(delete)="{item}">
        <i class='bx bx-trash text-danger' @click.stop.prevent="del(item)"></i>
      </template>
      <template #cell(status)="{item}">
        <status v-bind="{item}" />
      </template>
      <template #empty="scope">
        <div class="text-center m-2">{{ scope.emptyText }}</div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import resource from '@/resources/Package'
import Status from '@/components/Status.vue'

export default {
  components: {Status},
  data() {
    return {
      resource
    };
  },
  props: {
    shipment: {
      type: Object,
      required: true
    },
    packages: {
      type: Array,
      required: true
    }
  },
  computed: {
    canEdit() {
      return this.shipment.$can('edit')
    },
    fields() {
      return [
        {
          key: 'index',
          label: ''
        },
        ...resource.fields,
        this.canEdit ? {
          key: 'delete', 
          label: '',
        } : null
      ].filter(Boolean)
    }
  },
  methods: {
    async del(item) {
      let confirm = await this.$bvModal.msgBoxConfirm(this.$t('item.confirm-delete',{item: this.$t(`${this.resource.plural}.singular`)}),{
        cancelTitle: this.$t('common.cancel'),
        okTitle: this.$t('item.delete'),
        okVariant: 'danger'
      })

      if(confirm) {
        await item.$delete()
        this.$root.$emit('item:refresh')
      }
    }
  }
}
</script>